import React, { useState } from "react";
function Services(){
	const [language, setLanguage] = useState("English");
	function onChangeValue(event) {
		setLanguage(event.target.value);
	  }
    return(
        <section className="Feautes section">
            <div className="container" style={{textAlign:"left"}}>
				<p onChange={onChangeValue}>
					<label style={{marginRight: "1em",marginBottom: "1em"}}><input type="radio" value="English" name="language" checked={language === "English"} /> English</label>
					<label><input type="radio" value="Kannada" name="language" checked={language === "Kannada"}/> Kannada</label>
				</p>
				<p style={{fontWeight: "900", fontSize: "19px"}}>Social Work and Help:</p>
				<hr />
				<div class="image">
					<img src="img/help/1.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>1. Contribution to Mr.Ramesh for his Treatment</p>
				</div>
                <hr/>
    		</div>			
            <div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/2.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>2. Contribution given to Mr.Manoj Poojary for his treatment of Kidney Dialysis.</p>
				</div>
                <hr/>
    		</div>			
            <div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/3.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>3. Contribution to Ms.Santrupthi for her treatment.</p>
				</div>
                <hr/>
    		</div>
            <div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/4.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>4. Contribution given to Ms.Srusti for her Health Treatment.</p>
				</div>
                <hr/>
    		</div>
            <div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/5.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>5. Donation given to Government Primary School Irvathuru for their Talents day Celebration.</p>
				</div>
                <hr/>
    		</div>
            <div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/6.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>6. Contribution given 50 kg Rice to Mr.Naveen poojary and his family.</p>
				</div>
                <hr/>
    		</div>
            <div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/7.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>7. Amount given to Sampath family for his sister cancer treatment.</p>
				</div>
                <hr/>
    		</div>
            <div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/8.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>8. Shramadana by the Belaku Charitable trust in Irvathuru Temple premises.</p>
				</div>
                <hr/>
    		</div>
			<div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/9.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>9. Contribution given to Mr.Gururaj for his treatment.</p>
				</div>
                <hr/>
    		</div>
            <div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/10.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>10. Contribution given to the Cancer Patient.</p>
				</div>
                <hr/>
    		</div>
			<div className="container" style={{textAlign:"left"}}>
				<div class="image">
					<img src="img/help/11.jpg" alt="#" style={{width:"20em",padding:"1em"}} />
				</div>
				<div class="text">
					<p style={{fontWeight:900}}>11. Blood Donation by the trustee’s at Kasturba Hospital, Manipal.</p>
				</div>
                <hr/>
    		</div>
        </section>
    );
}
export default Services;