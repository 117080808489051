function About(){
    return(
        <section className="Feautes section">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title">
							<p style={{fontWeight: "900", fontSize: "19px"}}>ಬೆಳಕು ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್ ಕೊಳಕೆಯಿಂದ ಸೌಲಭ್ಯ ವಿತರಣೆ</p>
							<hr />
							<p>
								ಜ.14,2024 ರಂದು ಬೆಳ್ತಂಗಡಿ ತಾಲೂಕಿನ ಸುಲ್ಕೇರಿ ಗ್ರಾಮದ ನವೀನ್ ಪೂಜಾರಿಯವರು ಕಳೆದ 10 ವರ್ಷಗಳಿಂದ ಅನಾರೋಗ್ಯದಿಂದ ಬಳಲುತ್ತಿದ್ದು,
								ಈ ಕುಟುಂಬದ ಪರಿಸ್ಥಿತಿಯನ್ನು ಅರ್ಥೈಸಿಕೊಂಡ ಬೆಳಕು ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್(ರಿ.)ಕೊಳಕೆ, ಇರ್ವತ್ತೂರು ಕಾರ್ಕಳ ತಾಲೂಕು ಇದರ ವತಿಯಿಂದ
								ಬೆಳ್ತಂಗಡಿ ಘಟಕದ 8 ಮಂದಿ ಸದಸ್ಯರ ಸಹಕಾರದಿಂದ ಹಾಗೂ ಟ್ರಸ್ಟ್ ನ ಟ್ರಸ್ಟಿಗಳು ಮತ್ತು ಸರ್ವ ಸದಸ್ಯರ ಆಶೀರ್ವಾದದೊಂದಿಗೆ ನವೀನ್ ಪೂಜಾರಿ
								ಅವರ ಕುಟುಂಬಕ್ಕೆ 50 Kg ಅಕ್ಕಿಯನ್ನು ವಿತರಿಸಿ, ಶೀಘ್ರ ಗುಣಮುಖರಾಗುವಂತೆ ಭಗವಂತನಲ್ಲಿ ಬೇಡಿಕೊಳ್ಳಲಾಯಿತು.ಹಾಗೂ ಅವರ ತಾಯಿಯಾದ
								ಕೂಸಮ್ಮರವರಿಗೂ ನರದ ಸಮಸ್ಯೆಯಿದ್ದು ಈ ಬಗ್ಗೆ ಉಡುಪಿಯ ಎಸ್.ಡಿ.ಎಂ ಆಸ್ಪತ್ರೆಯಲ್ಲಿ ಚಿಕಿತ್ಸೆಗೆ ಸಹಕಾರಿಯಾಗುವಂತೆ ಆಸ್ಪತ್ರೆಯ ಸಿಬ್ಬಂದಿ ವರ್ಗದವರಲ್ಲಿ
								ಮಾತಾಡಿ ವ್ಯವಸ್ಥೆ ಮಾಡಿ ಕೊಡಲಾಗುವುದ ಎಂಬ ಭರವಸೆಯ ಮುಖಾಂತರ ಟ್ರಸ್ಟ್ ನ ಸದಸ್ಯರಾದ ಹರಿಣಿ.ಎಲ್ ಪೂಜಾರಿ ಧೈರ್ಯ ತುಂಬಿದರು.
							</p>
							<p>
								ಈ ಸಂದರ್ಭದಲ್ಲಿ ಸುಲ್ಕೇರಿ ಗ್ರಾಮ ಪಂಚಾಯತಿನ ನಿಕಟಪೂರ್ವ ಅಧ್ಯಕ್ಷ ನಾರಾಯಣ್ ಪೂಜಾರಿ, ಬೆಳಕು ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್ ನ ಅಧ್ಯಕ್ಷ ವಿಶ್ವನಾಥ ಪೂಜಾರಿ,
								ಸದಸ್ಯರಾದ ದಿನೇಶ್, ಹಾಗೂ ಸುಲ್ಕೇರಿ ಬಿಲ್ಲವ ಸಂಘದ ಉಪಾಧ್ಯಕ್ಷರಾದ ಶಂಕರ್ ಪೂಜಾರಿ, ನಿರ್ದೇಶಕರಾದ ಕೀರ್ತನ್, ಪೂಜಾರಿ ಹಾಗೂ ಸ್ಥಳೀಯರು ಉಪಸ್ಥಿತರಿದ್ದರು.
							</p>
							<img src="img/202401/14/1.jpeg" alt="#" style={{width:"100%"}} />
							<p>ಸುದ್ದಿ ನ್ಯೂಸ್ ನಲ್ಲಿ ಪ್ರಕಟಣೆ ಗೊಂಡಿದೆ. <a href="https://belthangady.suddinews.com/archives/674374" style={{color:"red"}}>ಓದಿರಿ</a> </p>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

export default About;