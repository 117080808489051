import React, { useState } from "react";
function Contact(){
    const [language, setLanguage] = useState("English");
	function onChangeValue(event) {
		setLanguage(event.target.value);
	  }
    return(
        <section className="Feautes section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <p onChange={onChangeValue}>
									<label style={{marginRight: "1em",marginBottom: "1em"}}><input type="radio" value="English" name="language" checked={language === "English"} /> English</label>
									<label><input type="radio" value="Kannada" name="language" checked={language === "Kannada"}/> Kannada</label>
								</p>
                    {language ==="English" &&<div>
                        <p style={{fontWeight: "900", fontSize: "19px"}}>Reach US:</p>
                        <hr />
                        <p style={{paddingLeft:"5em",fontStyle:"italic",fontWeight:"900"}}>Belaku Charitable Trust (R)</p>
                        <p style={{paddingLeft:"5em",fontStyle:"italic"}}>Kolake Irvathuru</p>
                        <p style={{paddingLeft:"5em",fontStyle:"italic"}}>Hosavaklu, Aranthabettu</p>
                        <p style={{paddingLeft:"5em",fontStyle:"italic"}}>Karkala Taluk</p>
                        <p style={{paddingLeft:"5em",fontStyle:"italic"}}>Udupi District - 574114</p>
                        <p style={{paddingLeft:"5em",fontStyle:"italic"}}>Contact: +91 9731736283 (Vishwanatha Poojary)</p>
                        <p style={{paddingLeft:"5em",fontStyle:"italic"}}>Email: belakucharitable12@gmail.com</p>
                        <p style={{fontWeight: "900", fontSize: "19px",marginTop:"1em"}}>Donate US: </p>
                        <hr />
                        <div class="container" style={{textAlign:"center"}}>
                            <div class="image">
                                <img src="img/qr-code.jpeg" alt="#" style={{width:"20em",padding:"1em"}} />
                            </div>
                            <div class="text">
                                <p>
                                    Your small support may bring happiness to the society. 
                                </p>
                            </div>
                        </div>                            
                        <hr/>
                    </div>}
                    {language==="Kannada" && 
                        <div>
                            <p style={{fontWeight: "900", fontSize: "19px"}}>ನಮ್ಮ ವಿಳಾಸ:</p>
                            <hr />
                            <p style={{paddingLeft:"5em",fontStyle:"italic",fontWeight:"900"}}>ಬೆಳಕು ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್ (ರಿ.)</p>
                            <p style={{paddingLeft:"5em",fontStyle:"italic"}}>ಕೊಳಕೆ ಇರ್ವತ್ತೂರು.</p>
                            <p style={{paddingLeft:"5em",fontStyle:"italic"}}>ಹೊಸವಕ್ಲು ಅರಂತಬೆಟ್ಟು</p>
                            <p style={{paddingLeft:"5em",fontStyle:"italic"}}>ಕಾರ್ಕಳ ತಾಲೂಕು</p>
                            <p style={{paddingLeft:"5em",fontStyle:"italic"}}>ಉಡುಪಿ ಜಿಲ್ಲೆ - 574114</p>
                            <p style={{paddingLeft:"5em",fontStyle:"italic"}}>ಮೊಬೈಲ್: +91 9731736283 (ವಿಶ್ವನಾಥ ಪೂಜಾರಿ)</p>
                            <p style={{paddingLeft:"5em",fontStyle:"italic"}}>Email: belakucharitable12@gmail.com</p>
                            <p style={{fontWeight: "900", fontSize: "19px",marginTop:"1em"}}>ಧನ ಸಹಾಯ ಮಾಡುವಿರಾ? </p>
                            <hr />
                            <div class="container" style={{textAlign:"center"}}>
                            <div class="image">
                                <img src="img/qr-code.jpeg" alt="#" style={{width:"20em",padding:"1em"}} />
                            </div>
                            <div class="text">
                                <p>
                                    ನಿಮ್ಮ ಕಿಂಚಿತ್ತು ಸಹಾಯ ಕಷ್ಟದಲ್ಲಿರುವವರಿಗೆ ಆಶಾಕಿರಣವಾಗುವುದು.
                                </p>
                            </div>
                        </div>                            
                        <hr/>
                        </div>
                        
                        
                    }
                </div>
            </div>
        </div>
    </section>
    );
}
export default Contact;