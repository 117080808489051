import '../App.css';
import { Link } from 'react-router-dom';
function Header(){
    return(
        <div>
            <div>					
				</div>
				<header className="header" >
					<div className="topbar">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-5 col-12">
									<ul className="top-link">
										<li>
											<Link to="/" >
											<img src="img/point.png" alt="#" style={{width:"1.5em"}} className='blinking-image'/>
											ಬೆಳಕು ಟ್ರಸ್ಟ್
											</Link>
										</li>
										<li>
											<Link to="/services" className='glow'>
											<img src="img/point.png" alt="#" style={{width:"1.5em"}} className='blinking-image'/>
											ಸಮಾಜ ಸೇವೆ
											</Link>
										</li>
										<li>
											<Link to="/contact" className='glow'>
											<img src="img/point.png" alt="#" style={{width:"1.5em"}} className='blinking-image'/>
											ಸಂಪರ್ಕಿಸಿ											
											</Link>
										</li>
									</ul>							
								</div>
								<div className="col-lg-6 col-md-7 col-12">
									<ul className="top-contact">
										<li><i className="fa fa-phone"></i>
										<a href="tel:+919731736283">+91 9731736283</a></li>
										<li><i className="fa fa-envelope"></i><a href="mailto:belakucharitable12@gmail.com">belakucharitable12@gmail.com</a></li>
									</ul>	
								</div>
							</div>
						</div>
					</div>
					<div className="header-inner">
						<div className="container">
							<div className="inner">
								<div className="row">
									<div className="col-lg-3 col-md-3 col-12">
										<div className="logo">
											<Link to="/">
												<img src="img/logo.jpeg" alt="#" style={{width:"45px"}}/>
											</Link>
										</div>
										<div className="mobile-nav"></div>
									</div>
									<div className="col-lg-7 col-md-9 col-12">
										<div className="main-menu">
											<nav className="navigation">
												<ul className="nav menu">
													<li><Link to="/" >ಬೆಳಕು ಟ್ರಸ್ಟ್</Link></li>
													<li><Link to="/services" >ಸಮಾಜ ಸೇವೆ</Link></li>
													<li><Link to="/contact" >ಸಂಪರ್ಕಿಸಿ</Link></li>													
												</ul>
											</nav>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
        </div>
    )
}
export default Header;