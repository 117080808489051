import React, { useState } from "react";
function Home(){
	const [language, setLanguage] = useState("English");
	function onChangeValue(event) {
		setLanguage(event.target.value);
	  }
    return(
        <section className="Feautes section">
							<div className="container" style={{textAlign:"justify"}}>
								<p onChange={onChangeValue}>
									<label style={{marginRight: "1em",marginBottom: "1em"}}><input type="radio" value="English" name="language" checked={language === "English"} /> English</label>
									<label><input type="radio" value="Kannada" name="language" checked={language === "Kannada"}/> Kannada</label>
								</p>
								<p style={{fontWeight: "900", fontSize: "19px"}}></p>
								{language==="English" && <p style={{fontWeight: "900", fontSize: "19px"}}>About Trust:</p>}
								{language==="Kannada" && <p style={{fontWeight: "900", fontSize: "19px"}}>ಟ್ರಸ್ಟ್ ನ ಪರಿಚಯ:</p>}
								<hr />
								<div class="image">
									<img src="img/logo.jpeg" alt="#" style={{width:"20em",padding:"1em"}} />
								</div>
								<div class="text">
								{language==="English" && 
									<p>
										The Belaku Charitable trust is a non-profit social concern organization established on 
										Ugadi festival day on 22-03-2023 at Hosavaklu, Aranthabettu,Kolake Irvathuru Village, Karkala Taluk, 
										Udupi District. It primarily provides support to health and education and lends a helping 
										hand to the underprivileged. Having a vision of empowering the underprivileged in the society,
										it plays a special role in creating awareness among the people about health and education. 
										Its primary office is at Irvathur in Karkala Taluk. 
									</p>
								}
								{language==="Kannada" && 
									<p>
										ಬೆಳಕು ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್ (ರಿ.) ಉಡುಪಿ ಜಿಲ್ಲೆಯ ಕಾರ್ಕಳ ತಾಲೂಕಿನ ಕೊಳಕೆ ಇವ೯ತ್ತೂರು ಗ್ರಾಮದ ಹೊಸವಕ್ಲು, 
										ಅರಂತಬೆಟ್ಟು ಎಂಬಲ್ಲಿ ದಿನಾಂಕ 22-03-2020ರ ಯುಗಾದಿ ಹಬ್ಬದ ದಿನದಂದು ಸ್ಥಾಪನೆಯಾದ ಲಾಭರಹಿತ ಸಾಮಾಜಿಕ 
										ಕಳಕಳಿ ಹೊಂದಿದ ಸಂಸ್ಥೆಯಾಗಿದೆ. ಇದು ಪ್ರಾಥಮಿಕವಾಗಿ ಅರೋಗ್ಯ ಮತ್ತು ಶಿಕ್ಷಣಕ್ಕೆ ಪೂರಕವಾಗಿ ಸಹಾಯಸ್ತವನ್ನು 
										ನೀಡುತ್ತಬಂದಿದ್ದು ಅಶಕ್ತ ಜನರಿಗೆ ಸಹಾಯ ಹಸ್ತ ಗಳನ್ನು ನೀಡಿರುತ್ತದೆ. ಸಮಾಜದಲ್ಲಿ ಇರುವ ಅಶಕ್ತ ಜನರಿಗೆ 
										ಶಕ್ತಿಯನ್ನು ತುಂಬುವ ಹಿತದೃಷ್ಟಿಯನ್ನು ಹೊಂದಿದ್ದು ಆರೋಗ್ಯ ಮತ್ತು ಶಿಕ್ಷಣದ ಬಗ್ಗೆ ಜನರಲ್ಲಿ ಜಾಗೃತಿ
										 ಮೂಡಿಸುವಲ್ಲಿ ವಿಶೇಷ ಪಾತ್ರವಹಿಸುತ್ತದೆ. ಇದರ ಪ್ರಾಥಮಿಕ ಕಚೇರಿ ಕಾರ್ಕಳ ತಾಲೂಕಿನ ಇರ್ವತ್ತೂರಿನಲ್ಲಿ ಇರುತ್ತದೆ.
									</p>
								}
								</div>
								<hr/>
    						</div>
							{language==="English" && 
								<div className="container">
									<p style={{fontSize:"15px",fontWeight:"900"}}>It mainly focuses on:</p>
									<ol className="belaku-home-list" style={{paddingLeft:"5em",marginTop:"1em"}}>
										<li>Relief to the Poor and Needy.</li>
										<li>Medical Relief.</li>
										<li>Establish research and training centres for the furtherance of educations.</li>
										<li>To establish centres for employment generation.</li>
										<li>Development of Blood bank and to organize Blood donation camps.</li>
										<li>To organizing the Medical camps in rural areas.</li>
										<li>To establish training centre in Educational sectors etc.</li>
										<li>Motivating to Improvement of Skills and Development of the Society.</li>
									</ol>	
									<hr/>
								</div>
							}
							{language==="Kannada" && 
								<div className="container">
									<p style={{fontSize:"15px",fontWeight:"900"}}>ಟ್ರಸ್ಟ್ ಮುಖ್ಯವಾಗಿ ಈ ಕೆಳಗಿನ ವಿಷಯಗಳ ಬಗ್ಗೆ ವಿಶೇಷ ಕಾಳಜಿ ವಹಿಸಿದೆ:</p>
									<ol className="belaku-home-list" style={{paddingLeft:"5em",marginTop:"1em"}}>
										<li>ಬಡವರು ಮತ್ತು ನಿರ್ಗತಿಕರಿಗೆ ಪರಿಹಾರ.</li>
										<li>ವೈದ್ಯಕೀಯ ಪರಿಹಾರ.</li>
										<li>ಶಿಕ್ಷಣ ಮುಂದುವರಿಕೆಗಾಗಿ ಸಂಶೋಧನೆ ಮತ್ತು ತರಬೇತಿ ಕೇಂದ್ರವನ್ನು ಸ್ಥಾಪಿಸುವುದು.</li>
										<li>ಉದ್ಯೋಗ ಕೇಂದ್ರಗಳನ್ನು ಸ್ಥಾಪಿಸುವುದು.</li>
										<li>ರಕ್ತನಿಧಿ ಅಭಿವೃದ್ಧಿ ಮತ್ತು ರಕ್ತ ಶಿಬಿರಗಳನ್ನು ಆಯೋಜಿಸುವುದು.</li>
										<li>ಗ್ರಾಮೀಣ ಪ್ರದೇಶಗಳಲ್ಲಿ ವೈದ್ಯಕೀಯ ಕ್ಯಾಂಪಸ್ ಆಯೋಜಿಸುವುದು.</li>
										<li>ಶೈಕ್ಷಣಿಕ ಕ್ಷೇತ್ರಗಳಲ್ಲಿ ತರಬೇತಿ ಕೇಂದ್ರವನ್ನು ಸ್ಥಾಪಿಸುವುದು ಇತ್ಯಾದಿ...</li>
										<li>ಕೌಶಲ್ಯಗಳ ಸುಧಾರಣೆ ಮತ್ತು ಸಮಾಜದ ಅಭಿವೃದ್ಧಿಗೆ ಪ್ರೇರೇಪಿಸುವುದು.</li>
									</ol>	
									<hr/>
								</div>
							}
							<div className="container" style={{textAlign:"left"}}>		
								{language==="English" && <p style={{fontWeight: "900", fontSize: "19px"}}>Programs and Events:</p>}
								{language==="Kannada" && <p style={{fontWeight: "900", fontSize: "19px"}}>ಕಾರ್ಯಕ್ರಮಗಳು:</p>}						
								<div class="text">
									{language==="English" && <p style={{fontWeight:"bold",marginBottom:"1px"}}>1. Free Health Checkup Camp</p>}
									{language==="Kannada" && <p style={{fontWeight:"bold",marginBottom:"1px"}}>1. ಉಚಿತ ಆರೋಗ್ಯ ತಪಾಸಣೆ ಶಿಬಿರ</p>}
									{language==="English" && 
										<p>
											On 21/04/2024 a free health check-up was conducted in Collaboration 
											with Belaku charitable trust and primary Health center irvathuru and
											 Nitte Gazriya speciality hospital. In the program, more than 100 
											 campers came and took advantage of the health check-up. 
											 The program was presided over by president vishwanath poojary and 
											 inaugurated by Bhaskar S Kotyan,a businessman
											  from Irvathuru,Shubakar Anchan,Public Relations Officer of 
											  Gajriya hospital, was the chief guest and told the information 
											  about the benefits of the free check-up camp was given to 
											  the campers. The dignitaries of the dias Jayakeerthi kadamba,
											  Dr.Yashodara M,Rajendra poojari,Dr.Naveen kamath,Dr.Karthik Rao 
											  and trustees of the trust and the members were present.
										</p>
									}
									{language==="Kannada" && <p>
										ದಿನಾಂಕ 21/05/2023 ರಂದು ಬೆಳಕು  ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್  ಹಾಗೂ ಪ್ರಾಥಮಿಕ  
										ಆರೋಗ್ಯ  ಕೇಂದ್ರ  ಇವ೯ತ್ತೂರು ಮತ್ತು ನಿಟ್ಟೆ ಗಾಜ್ರಿಯ ಸ್ಪೆಷಾಲಿಟಿ ಆಸ್ಪತ್ರೆ ಕಾರ್ಕಳ 
										ಇವರ ಸಹಕಾರದಲ್ಲಿ ಉಚಿತ ವೈದ್ಯಕೀಯ ತಪಾಸಣೆ  ಇವ೯ತ್ತೂರಿನಲ್ಲಿ ನಡೆಯಿತು. 
										ಈ ಕಾರ್ಯಕ್ರಮವು ಟ್ರಸ್ಟ್ ನ ಅಧ್ಯಕ್ಷರಾದ ವಿಶ್ವನಾಥ್ ಪೂಜಾರಿಯವರ ಅಧ್ಯಕ್ಷತೆಯಲ್ಲಿ ನಡೆಯಿತು. 
										ನೂರಕ್ಕೂ ಹೆಚ್ಚಿನ  ಸಂಖ್ಯೆಯಲ್ಲಿ  ಶಿಬಿರಾರ್ಥಿಗಳು  ಬಂದು ಆರೋಗ್ಯ 
										ತಪಾಸಣೆ  ಉಪಯೋಗ  ಪಡೆದುಕೊಂಡರು. ಕಾರ್ಯಕ್ರಮವನ್ನು  ಇವ೯ತ್ತೂರಿನ  
										ಉದ್ಯಮಿಯಾದ ಭಾಸ್ಕರ್ .ಎಸ್.ಕೋಟ್ಯಾನ್ ಉದ್ಘಾಟಿಸಿದರು. ಮುಖ್ಯ  ಅತಿಥಿಗಳಾಗಿ
										 ಗಾಜ್ರಿಯ ಆಸ್ಪತ್ರೆಯ  ಜನಸಂಪರ್ಕ  ಅಧಿಕಾರಿಯಾದ ಶುಭಕರ್  ಅಂಚನ್ ಉಚಿತ
										  ತಪಾಸಣೆ ಶಿಬಿರದಿಂದ ಆಗುವ ಪ್ರಯೋಜನಗಳ ಕುರಿತು ಮಾಹಿತಿಯನ್ನು ಶಿಬಿರಾರ್ಥಿಗಳಿಗೆ 
										  ತಿಳಿಸಿದರು.ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ  ಊರಿನ  ಗಣ್ಯರಾದ  ಜಯಕೀತಿ೯ ಕಡಂಬ ಊರಿನ
										   ಆಸ್ಪತ್ರೆಯ ವೈದ್ಯಾಧಿಕಾರಿ ಯಶೋಧರ .ಎಂ. ರಾಜೇಂದ್ರ ಪೂಜಾರಿ ,ಡಾ. ನವೀನ್
										    ಕಾಮತ್, ಡಾಕ್ಟರ್ ಕಾರ್ತಿಕ್ ರಾವ್, ಹಾಗೂ ಟ್ರಸ್ಟ್ ನ ಟ್ರಸ್ಟಿಗಳು ಮತ್ತು ಸದಸ್ಯರು
											  ಉಪಸ್ಥಿತರಿದ್ದರು.
									</p>
									}
								</div>
								<div class="image">
									<img src="img/event/1.0.jpg" alt="#" style={{width:"45em",padding:"1em"}} />
								</div>
								<hr/>
							</div>	
							<div className="container" style={{textAlign:"left"}}>								
								<div class="text">
									{language==="English" && <p style={{fontWeight:"bold",marginBottom:"1px"}}>2. Belaku Charitable trust Logo launch program</p>}
									{language==="Kannada" && <p style={{fontWeight:"bold",marginBottom:"1px"}}>2. ಬೆಳಕು ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್ ಲೋಗೋ ಲೋಕಾರ್ಪಣೆ ಕಾರ್ಯಕ್ರಮ</p>}
									{language==="English" && <div><p>
										On 24/12/2023 in Irvathuru,the Logo of the Trust was 
										released by the Belaku Charitable Trust. The program
										 was presided over by Trustee Harish. Former member of
										  Zilla Panchayath Uday s Kotyan,Journalist Balakrishna 
										  bhimaguli, Rakesh Kunjur, Trustee of Belaku trust and 
										  president of Billava bhandaver Gopal K Poojary,Gram 
										  panchayath member Shekar Anchan,Primary health center 
										  Physician Dr.yashodar, Rajendra poojary, member of the 
										  trust and president of Yuvaka mandal, village leader 
										   Vasantha pai, Jalaja, Jayanthi and members were present.
										    During this program, 20 kg of Rice was distributed
											 to a poor family.
											 <p>
											 Jayakeerthi Kadamba, an elder citizen of the village said in the program, 
											 <b>“By serving others selflessly, one can see God”. </b>
											 </p>
										</p></div>}
									{language==="Kannada" && <p>ದಿನಾಂಕ 24 /12/2023 ರಂದು ಇವ೯ತ್ತೂರು 
										ಬೆಳಕು ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್ ವತಿಯಿಂದ ಟ್ರಸ್ಟ್ ನ ಲೋಗೋ ಬಿಡುಗಡೆಗೊಳಿಸಲಾಯಿತು. 
										ಈ ಕಾರ್ಯಕ್ರಮವು ಟ್ರಸ್ಟ್ ನ ಟ್ರಸ್ಟಿಯಾದ ಹರೀಶ್ ರವರ ಅಧ್ಯಕ್ಷತೆಯಲ್ಲಿ ನಡೆಯಿತು.
										 ಜಿಲ್ಲಾಪಂಚಾಯತ್  ಮಾಜಿ  ಸದಸ್ಯ ಉದಯ.ಎಸ್ .ಕೋಟ್ಯಾನ್,  
										 ಪತ್ರಕರ್ತ ಬಾಲಕೃಷ್ಣ ಭೀಮಗುಳಿ, ರಾಕೇಶ್ ಕುಂಜೂರು, ಬೆಳಕು  ಟ್ರಸ್ಟ್ ನ 
										  ಟ್ರಸ್ಟಿ  ಹಾಗೂ ಬಿಲ್ಲವ  ಬಾಂಧವೆರ್  ಅಧ್ಯಕ್ಷರಾದ  ಗೋಪಾಲ್ .ಕೆ. ಪೂಜಾರಿ, 
										   ಗ್ರಾಮ  ಪಂಚಾಯತ್ ಸದಸ್ಯರಾದ ಶೇಖರ್ ಅಂಚನ್, ಪ್ರಾಥಮಿಕ ಆರೋಗ್ಯ ಕೇಂದ್ರದ
										     ವೈದ್ಯಾಧಿಕಾರಿಯಾದ  ಯಶೋಧರ, ಬೆಳಕು ಟ್ರಸ್ಟ್‌ನ ಸದಸ್ಯ ಹಾಗೂ  
											 ಯುವಕ ಮಂಡಲದ ಅಧ್ಯಕ್ಷ ರಾಜೇಂದ್ರ ಪೂಜಾರಿ, ಊರಿನ ಮುಖಂಡರಾದ 
											  ವಸಂತ .ಪೈ, ಜಲಜ, ಜಯಂತಿ ಮತ್ತು ಟ್ರಸ್ಟ್ ನ ಟ್ರಸ್ಟಿಗಳು ಹಾಗೂ ಸದಸ್ಯರ 
											   ಸಮ್ಮುಖದಲ್ಲಿ ಲೋಗೋ ಲೋಕಾರ್ಪಣೆ ಹಾಗೂ ಗ್ರಾಮದ  ಒಂದು  ಬಡಕುಟುಂಬಕ್ಕೆ 
											   20 ಕೆಜಿ ಅಕ್ಕಿ ವಿತರಿಸಲಾಯಿತು. ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ ಊರಿನ  ಹಿರಿಯರಾದ
											     ಜಯಕೀರ್ತಿ ಕಡಂಬ ಇವರು <b>“ಪರರ ಸೇವೆಯನ್ನು ನಿಸ್ವಾರ್ಥದಿಂದ 
												 ಮಾಡುವ ಮೂಲಕ ಭಗವಂತನನ್ನು ನೋಡಲು ಸಾಧ್ಯ”</b>ಎಂದು 
												 ಹೇಳಿದರು.</p>
									}
								</div>
								<div class="image">
									<img src="img/event/2.0.jpg" alt="#" style={{width:"45em",padding:"1em"}} />
								</div>
								<div class="image">
									<img src="img/event/2.1.jpg" alt="#" style={{width:"45em",padding:"1em"}} />
								</div>
								<div class="image">
									<img src="img/event/2.2.jpg" alt="#" style={{width:"45em",padding:"1em"}} />
								</div>
								<hr/>
							</div>	
							<div className="container" style={{textAlign:"left"}}>								
								<div class="text">
									{language==="English" && <p style={{fontWeight:"bold",marginBottom:"1px"}}>3. Blood Donation Camp and Website Unveiling Program</p>}
									{language==="Kannada" && <p style={{fontWeight:"bold",marginBottom:"1px"}}>3. ರಕ್ತದಾನ ಶಿಬಿರ ಹಾಗೂ ವೆಬ್ ಸೈಟ್ ಅನಾವರಣ</p>}
									{language==="English" && <div><p>
									On 28/04/2024 under the leadership of Belaku Charitable Trust(R) Kolake Irvathur,
									 the joint auspices of Rotary club Karkala Rock city, Govt PU college Sanoor,
									  Yuva Vaahini (R) Karkala unit in collaboration with District Hospital blood
									   bank department ,Ajjarakaadu Udupi a blood donation camp and website 
									   unveiling program was held at Govt .PU college Sanoor. 
											 <p>
											 The program was presided over by Akshatha, trustee of the Trust .
											 Indian army Havaldar Tilak SP inaugurated the program.Mrs. Suchetha Kamath, 
											 Principal of Government PU College Sanoor, unveiled the website of the trust in the
											  presence of everyone. Karkala rotary club President Ro.Suresh Naik ,Dr.Veena kumari ,
											  Head of blood fund department, Ajjarakaadu District Hospital ,Udupi.  Mr.Balakrishna 
											  Bhimagoli,a senior weekly columnist of Udayavaani newspaper ,Karkala .Mr.Shashikant 
											  shettigar ,a businessman from Sanoor,Mr.Arun poojary manja ,President of Karkala Yuva
											   Vaahini (Re).Madhava Banderkar ,President of Balanjaneya Yuvaka sangh murattangadi ,
											   Sudhir Poojary ,Secretary of Sanjeevini Foundation ,Manipal,Udupi were also present. 
											   Dr.Veena Kumari said that it is possible to save precious lives by collecting blood 
											   from social service camps.she said that artificial blood cannot be created. 
											   A large number  of volunteers participated in the program and donated total of 
											   39 units of blood ,making the program a success.villagers of the town and para town,
											   Vishwanath poojary,President of the Trust ,Trustee Harish,Bharath poojary,Satish Mantradi ,
											   Mohan Jain,Suresh,Chethan and members were present in this program.
											 </p>
										</p></div>}
									{language==="Kannada" && <p>ದಿನಾಂಕ 28/04/2024 ರಂದು ಇರ್ವತ್ತೂರು ಬೆಳಕು ಚಾರಿಟೇಬಲ್ ಟ್ರಸ್ಟ್ (ರಿ)
										ಕೊಳಕೆ ಇರ್ವತ್ತೂರು ಇವರ ನೇತೃತ್ವದಲ್ಲಿ ರೋಟರಿ ಕ್ಲಬ್ ಕಾರ್ಕಳ ರಾಕ್ ಸಿಟಿ, ಸ. ಪ. ಪೂ ಕಾಲೇಜು ಸಾಣೂರ್, 
										ಯುವ ವಾಹಿನಿ (ರಿ ) ಕಾರ್ಕಳ ಘಟಕ ಇವರ ಜಂಟಿ ಆಶ್ರಯದಲ್ಲಿ ಜಿಲ್ಲಾ ಆಸ್ಪತ್ರೆ ರಕ್ತನಿಧಿ ವಿಭಾಗ ಅಜ್ಜರಕಾಡು
										 ಉಡುಪಿ ಇವರ ಸಹಯೋಗದೊಂದಿಗೆ ರಕ್ತದಾನ ಶಿಬಿರ ಹಾಗೂ ವೆಬ್ ಸೈಟ್ ಅನಾವರಣ ಕಾರ್ಯಕ್ರಮ ಸ ಪ ಪೂ 
										 ಕಾಲೇಜು ಸಾಣೂರ್ ಇಲ್ಲಿ ನಡೆಯಿತು.<br/>
										 ಈ ಕಾರ್ಯಕ್ರಮವು ಟ್ರಸ್ಟ್ ನ ಟ್ರಸ್ಟಿಯಾದ ಅಕ್ಷತಾ ಇವರ ಅಧ್ಯಕ್ಷತೆಯಲ್ಲಿ ನಡೆಯಿತು. ಇಂಡಿಯನ್ ಆರ್ಮಿಯಾದ 
										 ಹವಾಲ್ದಾರ್ ತಿಲಕ್ ಯಸ್ ಪಿ ಇವರು ಕಾರ್ಯಕ್ರಮ ಉದ್ಘಾಟಿಸಿದರು. ಸರಕಾರಿ ಪದವಿ ಪೂರ್ವ ಕಾಲೇಜು ಸಾಣೂರ್ 
										 ಇಲ್ಲಿಯ ಪ್ರಾಂಶುಪಾಲರಾದ ಶ್ರೀಮತಿ ಸುಚೇತಾ ಕಾಮತ್ ಇವರು ಎಲ್ಲರ ಸಮ್ಮುಖದಲ್ಲಿ ಟ್ರಸ್ಟ್ ನ ವೆಬ್ ಸೈಟ್ 
										 ಅನಾವರಣಗೊಳಿಸಿದರು. ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ ಮುಖ್ಯ ಅಥಿತಿಗಳಾಗಿ ಕಾರ್ಕಳ ರೋಟರಿ ಕ್ಲಬ್ ಅಧ್ಯಕ್ಷರಾದ ರೊ. 
										 ಸುರೇಶ್ ನಾಯಕ್, ರಕ್ತನಿಧಿ ವಿಭಾಗ ಅಜ್ಜರಕಾಡು ಜಿಲ್ಲಾಆಸ್ಪತ್ರೆ ಉಡುಪಿ ಇಲ್ಲಿಯ ಮುಖ್ಯಸ್ಥರಾದ ಡಾಕ್ಟರ್
										  ವೀಣಾ ಕುಮಾರಿ, ಉದಯವಾಣಿ ಪತ್ರಿಕೆ ಕಾರ್ಕಳ ಇಲ್ಲಿಯ ಹಿರಿಯ ವಾರದಿಗಾರರಾದ ಶ್ರೀ ಬಾಲಕೃಷ್ಣ 
										  ಭೀಮಗೋಳಿ ಸಾಣೂರಿನ ಉದ್ಯಮಿಯಾದ ಶ್ರೀ ಶಶಿಕಾಂತ್ ಶೆಟ್ಟಿಗಾರ್, ಕಾರ್ಕಳ ಯುವವಾಹಿನಿ (ರಿ )ಇದರ 
										  ಅಧ್ಯಕ್ಷರದ ಶ್ರೀ ಅರುಣ್ ಪೂಜಾರಿ ಮಾಂಜ, ಬಾಲಂಜನೇಯ ಯುವಕ ಸಂಘ ಮುರತ್ತಂಗಡಿ ಇದರ ಅಧ್ಯಕ್ಷರಾದ 
										  ಮಾಧವ ಬಂಡರ್ಕರ್, ಸಂಜೀವಿನಿ ಫೌಂಡೇಶನ್ ಮಣಿಪಾಲ, ಉಡುಪಿ ಇದರ ಜತೆ ಕಾರ್ಯದರ್ಶಿ ಯಾದ ಸುಧೀರ್ 
										  ಪೂಜಾರಿ ಉಪಸ್ಥಿತಿಯಲ್ಲಿದ್ದರು. ಡಾಕ್ಟರ್ ವೀಣಾ ಕುಮಾರಿ ಕಾರ್ಯಕ್ರಮ ಉದ್ದೇಶಿಸಿ ಸಮಾಜಮುಖಿ ಸೇವೆಯಾದ 
										  ಶಿಬಿರಗಳಿಂದ ರಕ್ತವನ್ನು ಸಂಗ್ರಹಿಸಿ ಅಮೂಲ್ಯ ಜೀವವನ್ನು ಉಳಿಸಲು ಸಾಧ್ಯ. ಕೃತಕ ರಕ್ತ ಸೃಷ್ಟಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ 
										  ಎಂದು ಮಾತನಾಡಿದರು.ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ ಹೆಚ್ಚಿನ ಸಂಖ್ಯೆಯಲ್ಲಿ ಶಿಭಿರಾರ್ಥಿಗಳು ಭಾಗವಹಿಸಿ ಒಟ್ಟು 39 
										  ಯೂನಿಟ್ ರಕ್ತದಾನ ಮಾಡಿ ಕಾರ್ಯಕ್ರಮವು ಯಶಸ್ವಿಯಾಯಿತು. ಈ ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ ಊರಿನ ಹಾಗೂ 
										  ಪರ ಊರಿನ ಗ್ರಾಮಸ್ಥರು, ಟ್ರಸ್ಟ್ ನ  ಅಧ್ಯಕ್ಷರಾದ ವಿಶ್ವನಾಥ್ ಪೂಜಾರಿ, ಟ್ರಸ್ಟಿಗಳಾದ ಹರೀಶ್,ಭರತ್ ಪೂಜಾರಿ, 
										  ಸತೀಶ್ ಮಂಟ್ರಾದಿ,ಮೋಹನ್ ಜೈನ್, ಸುರೇಶ್,ಚೇತನಾ ಮತ್ತು ಸದಸ್ಯರು ಉಪಸ್ಥಿತರಿದ್ದರು.
										 </p>
									}
								</div>
								<div class="image">
									<img src="img/event/3.0.jpg" alt="#" style={{width:"45em",padding:"1em"}} />
								</div>
								<div class="image">
									<img src="img/event/3.1.jpg" alt="#" style={{width:"45em",padding:"1em"}} />
								</div>
								<div class="image">
									<img src="img/event/3.2.jpg" alt="#" style={{width:"45em",padding:"1em"}} />
								</div>
								<hr/>
							</div>	
							
		</section>
    )
}
export default Home;