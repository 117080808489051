import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Home from './components/Home';
import Services from './components/Services';
import Contact from './components/Contact';
import React, {Component} from 'react';
import { 
    BrowserRouter as Router, Routes, 
    Route,Link ,Outlet,BrowserRouter
} from "react-router-dom"; 

export default class App extends Component {
	constructor(props) {
		super(); 
	}
	render() {
		let newDate = new Date();
	let year = newDate.getFullYear();
		return (
			<BrowserRouter>				
				<Header/>
				<Routes>
					<Route path="/" element={<Home />}/>
					<Route path="about" element={<About />} />
					<Route path="services" element={<Services />} />
					<Route path="contact" element={<Contact />} />
				</Routes>
				<Footer/>				
			</BrowserRouter>
		  );
	}
}