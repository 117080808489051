function Footer()
{
    let newDate = new Date();
	let year = newDate.getFullYear();
return (
    <footer id="footer" className="footer ">					
					<div className="copyright">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-12">
									<div className="copyright-content">
										<p>© Copyright {year}  |  All Rights Reserved by <a href="https://www.belakucharitabletrust.com" target="_blank">Belaku Charitable Trust</a> </p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
);
}
export default Footer;